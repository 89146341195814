<template>
  <div class="intro-y box p-2 sm:p-5 mt-5">
    <h4 class="text-lg font-medium leading-none mb-10">
      <app-i18n code="event.fields.responsible"></app-i18n>
    </h4>

    <div class="grid grid-cols-12 mt-5 gap-3 required relative">
      <label for="name" class="text-md font-medium leading-none items-center lg:col-span-4 col-span-12">
      {{ i18n('event.fields.name') }}
      </label>
      <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
        <input
          id="name"
          type="text"
          class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
          :placeholder="i18n('event.placeholder.name')"
          :value="name"
          @input="$emit('update:name', $event.target.value)"
        />
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5 gap-3 required relative">
      <label for="email" class="text-md font-medium leading-none items-center lg:col-span-4 col-span-12">
      {{ i18n('event.fields.email') }}
      </label>
      <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
        <input
          id="email"
          type="text"
          class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
          :placeholder="i18n('event.placeholder.email')"
          :value="email"
          @input="$emit('update:email', $event.target.value)"
        />
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5 gap-3 required relative">
      <label for="phoneNumber" class="text-md font-medium leading-none items-center lg:col-span-4 col-span-12">
      {{ i18n('event.fields.phoneNumber') }}
      </label>
      <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2" style="dir: ltr">
        <vue-tel-input
          id="phoneNumber"
          mode="international"
          :inputOptions="isRTL ? optionsAr : options"
          v-model="responsible.phoneNumber"
          @input="$emit('update:phoneNumber', $event.target.value)"
        ></vue-tel-input>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'event-form-responsible',
  props: {
    name: String,
    email: String,
    phoneNumber: String
  },
  emits: ['update:name', 'update:email', 'update:phoneNumber'],
  data() {
    return {
      responsible: {
        name: '',
        email: '',
        phoneNumber: this.phoneNumber
      },
      options: { placeholder: 'Enter the phone number' },
      optionsAr: { placeholder: 'ادخل رقم التليفون' }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'event/form/record',
      findLoading: 'event/form/findLoading',
      saveLoading: 'event/form/saveLoading'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    updateValue(value) {
      this.$emit('input', value) // update data in parent component
    }
  }
})
</script>
