<template>
  <!-- BEGIN: Modal Content -->
  <div id="timeTable-create-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="modal-title">
            <app-i18n code="event.timing.create"></app-i18n>
          </h2>
          <button type="button" data-dismiss="modal" class="header-close-button" @click="doReset()">
            <span>
              <svg viewBox="0 0 8 8" fill="currentColor" width="8" height="8" data-hook="close-medium">
                <path d="M7.2 0L4 3.2 0.8 0 0.1 0.7 3.3 4 0 7.3 0.7 8 4 4.7 7.3 8 8 7.3 4.7 4 7.9 0.7z"></path>
              </svg>
            </span>
          </button>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <div class="flex items-center gap-1">
              <label for="" class="form-label">{{ i18n('event.fields.title') }}</label>
              <!-- <div class="infoicon-tooltip">
                <div>
                  <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24" class="infoicon-svg"><path d="M13,15 L13,10 L11,10 L10,12 L11,12 L11,15 L10,16 L10,17 L14,17 L14,16 L13,15 Z M11,9 L13,9 L13,7 L11,7 L11,9 Z M12,20 C7.589,20 4,16.411 4,12 C4,7.589 7.589,4 12,4 C16.411,4 20,7.589 20,12 C20,16.411 16.411,20 12,20 L12,20 Z M12,3 C7.029,3 3,7.029 3,12 C3,16.971 7.029,21 12,21 C16.971,21 21,16.971 21,12 C21,7.029 16.971,3 12,3 L12,3 Z"></path></svg>
                </div>
              </div> -->
            </div>

            <div class="grid grid-cols-12 items-center gap-3 relative">
              <div class="lg:col-span-6 col-span-12">
                <input
                  id="title-en"
                  type="text"
                  class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.title.en"
                  :placeholder="i18n('common.english')"
                />
              </div>
              <div class="lg:col-span-6 col-span-12">
                <input
                  id="title-ar"
                  type="text"
                  class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.title.ar"
                  :placeholder="i18n('common.arabic')"
                />
              </div>
            </div>
          </div>

          <div class="col-span-12">
            <label for="" class="form-label">{{ i18n('event.fields.description') }}</label>

            <div class="grid grid-cols-12 items-center gap-3 relative">
              <div class="lg:col-span-6 col-span-12">
                <textarea
                  id="description-en"
                  :rows="4"
                  class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.description.en"
                  :placeholder="i18n('common.english')"
                ></textarea>
              </div>
              <div class="lg:col-span-6 col-span-12">
                <textarea
                  id="description-ar"
                  :rows="4"
                  class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.description.ar"
                  :placeholder="i18n('common.arabic')"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="col-span-12">
            <label for="" class="form-label">{{ i18n('event.fields.interval') }}</label>

            <div class="grid grid-cols-12 items-center gap-3 relative">
              <div class="col-span-4">
                <input
                  id="title-en"
                  type="number"
                  :min="0"
                  :max="12"
                  class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.intervalForm"
                  :placeholder="i18n('common.from')"
                />
              </div>
              <div class="col-span-4">
                <input
                  id="title-ar"
                  type="number"
                  :min="0"
                  :max="12"
                  class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.intervalTo"
                  :placeholder="i18n('common.to')"
                />
              </div>
              <div class="col-span-4">
                <TomSelect v-model="model.intervalTiming" class="w-full dark:bg-dark-9">
                  <!-- <option value="" disabled>Timing</option> -->
                  <option value="am" class="">{{ isRTL ? 'صباحاً' : 'AM' }}</option>
                  <option value="pm">{{ isRTL ? 'مساءاً' : 'PM' }}</option>
                </TomSelect>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center gap-2">
          <button id="submit-btn" type="button" class="btn-submit btn bg-theme-24 w-24 text-white cursor-pointer"
            :disabled="modelEmpty" @click="doSubmit()">
            {{ i18n('common.create') }}
          </button>
          <button type="button" data-dismiss="modal" class="btn-cancel btn bg-theme-32 text-black w-24" @click="doReset()">
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Message from '@/shared/message/toastify'

export default defineComponent({
  name: 'timeTable-create-modal',
  setup() {
    const hideModal = () => {
      cash('#timeTable-create-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  data() {
    return {
      model: {
        title: { en: '', ar: '' },
        description: { en: '', ar: '' },
        intervalForm: '',
        intervalTo: '',
        intervalTiming: 'am'
      },
      timing: {
        am: { en: 'am', ar: 'صباحاً' },
        pm: { en: 'pm', ar: 'مساءاً' }
      },
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    }),
    modelEmpty() {
      return !this.model.title.en || !this.model.title.ar ||
        !this.model.description.en || !this.model.description.ar ||
        !this.model.intervalForm || !this.model.intervalTo || !this.model.intervalTiming
    },
    interval() {
      let interval = { en: '', ar: '' }
      if (this.model.intervalForm && this.model.intervalTo && this.model.intervalTiming) {
        const timingAr = this.model.intervalTiming == 'am' ? 'صباحاً' : 'مساءاً'
        interval = {
          en: `${this.model.intervalForm}-${this.model.intervalTo} ${this.model.intervalTiming}`,
          ar: `${this.model.intervalForm}-${this.model.intervalTo} ${timingAr}`
        }
      }
      return interval
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.modelEmpty) {
        return false
      }

      return true
    },
    doReset() {
      this.model = {
        title: { en: '', ar: '' },
        description: { en: '', ar: '' },
        intervalForm: '',
        intervalTo: '',
        intervalTiming: ''
      }
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const record = {
        title: this.model.title,
        description: this.model.description,
        interval: this.interval
      }
      this.$emit('submit', record)
      this.doReset()
      this.hideModal()
    }
  }
})
</script>

<!-- Modal Dialog Style -->
<style lang="scss" scoped>
.modal .modal-dialog {
  box-shadow: 0 8px 8px 0 rgb(22 45 61 / 12%), 0 3px 24px 0 rgb(22 45 61 / 18%);
  border-radius: 15px 15px 8px 8px;
  background-color: #fff;
}
.modal-content {
  border-radius: 15px 15px 8px 8px;
}
@media (min-width: 640px) {
  .modal .modal-dialog {
    width: 600px;
  }
}
</style>

<!-- Modal Header Style -->
<style lang="scss" scoped>
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #f18b43;
$primary_color_hover: #ecab7d;

.modal-header {
  background: $primary_color;
  min-height: 60px;
  padding: 18px;
  padding-left: 30px;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  line-height: 24px;
}
.modal-title {
  display: inline-block;
  width: calc(100% - 40px);
  word-break: break-word;
}
.header-close-button {
  width: 24px;
  height: 24px;
  background-color: rgba(22,45,61,.24);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 18px;
  border-color: transparent;
  transition-duration: 100ms;
  transition-timing-function: linear;
  transition-property: background-color, color, border-color;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.header-close-button:hover {
  background-color: rgba(22,45,61,.3);
  color: #fff;
}
.header-close-button span{
  line-height: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<!-- Modal Body Style -->
<style lang="scss" scoped>
.modal-body {
  padding: 36px 30px 12px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: #162d3d;
}
.form-label {
  color: #32536A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap!important;
  display: inline-block!important;
}
.form-input:hover {
  // background-color: #EAF7FF;
  background-color: rgba(241, 139, 66, 0.1);
}
.infoicon-tooltip {
  position: relative;
  display: inline;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.infoicon-tooltip div {
  box-sizing: border-box;
}
.infoicon-tooltip div svg {
  color: #3899EC;
  vertical-align: middle;
}
</style>

<!-- Modal Footer Style -->
<style lang="scss" scoped>
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #f18b43;
$primary_color_hover: #ecab7d;

.modal-footer {
  border-top: none !important;
  padding: 23px 30px 30px;
}
.btn-submit {
  color: #FFFFFF;
  background-color: $primary_color;
  border-color: $primary_color;
}
.btn-submit:hover {
  background-color: $primary_color_hover;
  border-color: $primary_color_hover;
  color: #FFFFFF;
}
.btn-submit:disabled {
  color: #FFFFFF;
  background-color: rgba(22,45,61,.3);
  border-color: transparent;
}
.btn-cancel {
  background-color: transparent !important;
  border-color: $primary_color !important;
  color: $primary_color !important;
}
.btn-cancel:hover {
  background-color: $primary_color_hover !important;
  border-color: $primary_color_hover !important;
  color: #FFFFFF !important;
}
</style>
