/* eslint-disable */
// import * as yup from 'yup';
import { i18n } from '@/locales/i18n';
// import Roles from '@/security/roles';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import DateTimeField from '@/shared/fields/date-time-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field';
import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';
import { GenericModel } from '@/shared/model/generic-model';

function label(name) {
  return i18n(`event.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`event.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id'), { required: true }),
  cover: new StringField('cover', label('cover')),
  time: new DateTimeField('time', label('time')),
  isRemoved: new BooleanField('isRemoved', label('isRemoved')),

  title: new JsonField('title', label('title'), ['en', 'ar']),
  description: new JsonField('description', label('description'), ['en', 'ar']),
  eventMission: new JsonField('eventMission', label('eventMission'), ['en', 'ar']),
  eventVision: new JsonField('eventVision', label('eventVision'), ['en', 'ar']),
  eventObjectives: new JsonField('eventObjectives', label('eventObjectives'), ['en', 'ar']),
  eventAgenda: new JsonField('eventAgenda', label('eventAgenda'), ['en', 'ar']),
  eventTermsAndConditions: new JsonField('eventTermsAndConditions', label('eventTermsAndConditions'), ['en', 'ar']),
  
  responsible: new JsonField('responsible', label('responsible'), ['name', 'email', 'phoneNumber']),
  location: new JsonField('location', label('location'), ['lat', 'long']),
  // sponsors: new JsonField('sponsors', label('sponsors'), ['logo', 'link']),
  // timeTable: new JsonField('timeTable', label('timeTable'), ['title', 'description', 'interval']),
  sponsors: new StringArrayField('sponsors', label('sponsors')),
  timeTable: new StringArrayField('timeTable', label('timeTable')),
  
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class EventModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
