<template>
  <div class="intro-y box p-2 sm:p-5 mt-5">
    <h4 class="text-lg font-medium leading-none mb-10">
      <app-i18n code="event.fields.location"></app-i18n>
    </h4>

    <div class="grid grid-cols-12 mt-5 gap-3 required relative">
      <label for="lat" class="text-md font-medium leading-none items-center lg:col-span-4 col-span-12">
      {{ i18n('event.fields.lat') }}
      </label>
      <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
        <input
          id="lat"
          type="number"
          :min="-90" :max="90"
          class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
          :placeholder="i18n('event.placeholder.lat')"
          :value="lat"
          @input="$emit('update:lat', $event.target.value)"
        />
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5 gap-3 required relative">
      <label for="long" class="text-md font-medium leading-none items-center lg:col-span-4 col-span-12">
      {{ i18n('event.fields.long') }}
      </label>
      <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
        <input
          id="long"
          type="number"
          :min="-180" :max="180"
          class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
          :placeholder="i18n('event.placeholder.long')"
          :value="long"
          @input="$emit('update:long', $event.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'event-form-location',
  props: {
    lat: String,
    long: String
  },
  emits: ['update:lat', 'update:long'],
  data() {
    return {
      location: {
        lat: '',
        long: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'event/form/record',
      findLoading: 'event/form/findLoading',
      saveLoading: 'event/form/saveLoading'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    updateValue(value) {
      this.$emit('input', value) // update data in parent component
    }
  }
})
</script>
