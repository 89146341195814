<template>
  <div class="intro-y box p-2 sm:p-5 mt-5">
    <div class="flex items-center justify-between gap-2 mb-5">
      <h4 class="text-lg font-medium leading-none mb-0">
        <app-i18n code="event.fields.timeTable"></app-i18n>
      </h4>

      <button type="button" class="btn bg-theme-31 text-white sm:w-auto flex items-center gap-2" @click="showModal()">
        <PlusIcon class="w-4 h-4" /> {{ i18n('common.add') }}
      </button>
    </div>

    <div ref="table" class="relative">
      <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />

      <div class="overflow-x-auto">
        <table id="eventTimeTable" ref="tableRef" class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th v-for="col in columns" :key="col.name" class="text-center whitespace-nowrap">
                {{ i18n(col.label) }}
              </th>
            </tr>
          </thead>

          <tbody v-if="rows.length">
            <tr v-for="(row, index) in rows" :key="index" class="intro-x">
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row, 'interval') }}
                </div>
              </td>
              <td class="table-report__action w-56" id="ignore-6">
                <div class="flex justify-center items-center gap-3 text-theme-24">
                  <button type="button" class="text-theme-24 flex items-center gap-2 whitespace-nowrap" @click="doDelete(index)">
                    <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-empty-page v-if="!rows.length && !loading"
          :label="i18n('empty.timing')"
          label-class="text-md font-medium"
          icon-size="3x"
          class="box w-full"
          style="padding: 50px 0;"
        ></app-empty-page>
      </div>
    </div>

    <timeTable-create-modal
      ref="modal"
      @submit="doCreate"
      @delete="doDelete"
    ></timeTable-create-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import TimeTableCreateModal from '@/views/event/modal/timeTable-create-modal.vue'

export default defineComponent({
  name: 'event-form-timeTable',
  props: {
    loading: {
      type: Boolean
    },
    rows: {
      type: Array,
      default: () => []
    }
  },
  components: {
    [TimeTableCreateModal.name]: TimeTableCreateModal
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tableRef
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'title',
          field: 'title',
          label: 'event.fields.title',
          align: 'center'
          // required: true,
        },
        {
          name: 'description',
          field: 'description',
          label: 'event.fields.description',
          align: 'center'
          // required: true,
        },
        {
          name: 'interval',
          field: 'interval',
          label: 'event.fields.interval',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    showModal() {
      cash('#timeTable-create-modal').modal('show')
    },
    doCreate(record) {
      this.$emit('add', record)
    },
    doDelete(index) {
      this.$emit('remove', index)
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
