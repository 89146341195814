<template>
  <div class="intro-y box p-5">
    <h4 class="text-lg font-medium leading-none mb-10">
      <app-i18n code="event.fields.eventTermsAndConditions"></app-i18n>
    </h4>
    <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="findLoading" />

    <!-- <div class="flex items-center gap-4 mb-5">
      <div class="flex items-center gap-1" @click="selectedLanguage = 'en'">
        <input v-model="selectedLanguage" class="cursor-pointer" type="radio" id="en" name="language" value="en" checked>
        <label for="en" class="cursor-pointer">{{ i18n('common.english') }}</label>
      </div>
      <div class="flex items-center gap-1 cursor-pointer" @click="selectedLanguage = 'ar'">
        <input v-model="selectedLanguage" class="cursor-pointer" type="radio" id="ar" name="language" value="ar">
        <label for="ar" class="cursor-pointer">{{ i18n('common.arabic') }}</label>
      </div>
    </div> -->

    <!-- <div>
      <CKEditor
        v-model="valueInput[selectedLanguage]"
        v-on:input="updateValue($event.target.value)"
        :editor="classicEditor"
        :config="editorConfig"
        :read-only="saveLoading"
      />
    </div> -->

    <div class="grid grid-cols-12 mt-5 gap-3">
      <div class="xl:col-span-6 col-span-12">
        <div class="text-gray-500 text-md font-medium leading-none mb-3">{{ i18n('common.english') }}</div>
        <CKEditor
          v-model="valueInput.en"
          :editor="classicEditor"
          :config="editorConfig"
          :read-only="saveLoading"
        />
      </div>

      <div class="xl:col-span-6 col-span-12 xl:mt-0 mt-2">
        <div class="text-gray-500 text-md font-medium leading-none mb-3">{{ i18n('common.arabic') }}</div>
        <CKEditor
          v-model="valueInput.ar"
          :editor="classicEditor"
          :config="editorConfig"
          :read-only="saveLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'

// import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor'
// import BalloonEditor from '@ckeditor/ckeditor5-editor-balloon/src/ballooneditor'
// import DocumentEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor'

export default defineComponent({
  name: 'event-form-terms-and-conditions',
  // props: {
  //   value: {
  //     get: () => this.value,
  //     set: (val) => {
  //       this.value = val
  //     }
  //   }
  // },
  props: ['value'],
  setup() {
    const classicEditor = ClassicEditor
    const editorConfig = {
      cloudServices: {
        tokenUrl: '',
        uploadUrl: ''
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'subscript',
          'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      }
    }
    // const editorData = ref('<p>Content of the editor.</p>')
    return {
      classicEditor,
      editorConfig
      // editorData
    }
  },
  data() {
    return {
      // valueInput: {
      //   en: '',
      //   ar: ''
      // },
      valueInput: this.value,
      selectedLanguage: ''
    }
  },
  computed: {
    ...mapGetters({
      findLoading: 'setting/policies/findLoading',
      saveLoading: 'setting/policies/saveLoading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    }),
    languageOptions() {
      return [
        { value: 'en', label: this.i18n('common.english') },
        { value: 'ar', label: this.i18n('common.arabic') }
      ]
    }
    // valueInput: {
    //   get: function() {
    //     return this.value || { en: '', ar: '' }
    //   },
    //   set: function(value) {
    //     this.value = value
    //   }
    // }
  },
  created() {
    this.selectedLanguage = this.language
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    updateValue(value) {
      debugger
      this.$emit('input', value)
    }
  },
  watch: {
    language(newval) {
      this.tab = newval == 'ar' ? 1 : 0
    }
  }
})
</script>

<style lang="scss" scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
