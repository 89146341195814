<template>
  <!-- BEGIN: Modal Content -->
  <div id="sponsor-create-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="modal-title">
            <app-i18n code="event.sponsor.create"></app-i18n>
          </h2>
          <button type="button" data-dismiss="modal" class="header-close-button" @click="doReset()">
            <span>
              <svg viewBox="0 0 8 8" fill="currentColor" width="8" height="8" data-hook="close-medium">
                <path d="M7.2 0L4 3.2 0.8 0 0.1 0.7 3.3 4 0 7.3 0.7 8 4 4.7 7.3 8 8 7.3 4.7 4 7.9 0.7z"></path>
              </svg>
            </span>
          </button>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <div class="flex items-center gap-1">
              <label for="" class="form-label">{{ i18n('event.fields.link') }}</label>
            </div>

            <div class="grid grid-cols-12 items-center gap-3 relative">
              <div class="col-span-12">
                <input
                  id="title-en"
                  type="url"
                  class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.link"
                  :placeholder="i18n('common.english')"
                />
              </div>
            </div>
          </div>

          <div class="col-span-12">
            <label for="" class="form-label">{{ i18n('event.fields.logo') }}</label>

            <div class="grid grid-cols-12 items-center gap-3 relative">
              <div class="col-span-12">
                <Uploader ref="uploader" class="mt-0"></Uploader>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center gap-2">
          <button id="submit-btn" type="button" class="btn-submit btn bg-theme-24 w-24 text-white cursor-pointer"
            :disabled="!model.link" @click="doSubmit()">
            {{ i18n('common.create') }}
          </button>
          <button type="button" data-dismiss="modal" class="btn-cancel btn bg-theme-32 text-black w-24" @click="doReset()">
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/event/uploader.vue'

export default defineComponent({
  name: 'sponsor-create-modal',
  components: {
    Uploader
  },
  setup() {
    const hideModal = () => {
      cash('#sponsor-create-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  data() {
    return {
      model: {
        link: '',
        logo: '',
        logoFile: null
      },
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    }),
    logoImg() {
      return this.model && this.model.logo
        ? this.model.cover
        : null
        // : require('@/assets/images/profile-1.jpg')
    },
    logoFile() {
      return this.$refs.uploader && this.$refs.uploader.filesToSave && this.$refs.uploader.filesToSave.length
        ? this.$refs.uploader.filesToSave[0]
        : null
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.modelEmpty) {
        return false
      }

      return true
    },
    doReset() {
      this.model = {
        link: '',
        logo: '',
        logoFile: null
      }
      // this.$refs.uploader.clearDropzone()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      this.model.logo = URL.createObjectURL(this.logoFile)
      this.model.logoFile = this.logoFile
      this.$emit('submit', this.model)
      this.doReset()
      this.hideModal()
    }
  }
})
</script>

<!-- Modal Dialog Style -->
<style lang="scss" scoped>
.modal .modal-dialog {
  box-shadow: 0 8px 8px 0 rgb(22 45 61 / 12%), 0 3px 24px 0 rgb(22 45 61 / 18%);
  border-radius: 15px 15px 8px 8px;
  background-color: #fff;
}
.modal-content {
  border-radius: 15px 15px 8px 8px;
}
@media (min-width: 640px) {
  .modal .modal-dialog {
    width: 600px;
  }
}
</style>

<!-- Modal Header Style -->
<style lang="scss" scoped>
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #f18b43;
$primary_color_hover: #ecab7d;

.modal-header {
  background: $primary_color;
  min-height: 60px;
  padding: 18px;
  padding-left: 30px;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  line-height: 24px;
}
.modal-title {
  display: inline-block;
  width: calc(100% - 40px);
  word-break: break-word;
}
.header-close-button {
  width: 24px;
  height: 24px;
  background-color: rgba(22,45,61,.24);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 18px;
  border-color: transparent;
  transition-duration: 100ms;
  transition-timing-function: linear;
  transition-property: background-color, color, border-color;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.header-close-button:hover {
  background-color: rgba(22,45,61,.3);
  color: #fff;
}
.header-close-button span{
  line-height: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<!-- Modal Body Style -->
<style lang="scss" scoped>
.modal-body {
  padding: 36px 30px 12px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: #162d3d;
}
.form-label {
  color: #32536A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap!important;
  display: inline-block!important;
}
.form-input:hover {
  // background-color: #EAF7FF;
  background-color: rgba(241, 139, 66, 0.1);
}
.infoicon-tooltip {
  position: relative;
  display: inline;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.infoicon-tooltip div {
  box-sizing: border-box;
}
.infoicon-tooltip div svg {
  color: #3899EC;
  vertical-align: middle;
}
</style>

<!-- Modal Footer Style -->
<style lang="scss" scoped>
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #f18b43;
$primary_color_hover: #ecab7d;

.modal-footer {
  border-top: none !important;
  padding: 23px 30px 30px;
}
.btn-submit {
  color: #FFFFFF;
  background-color: $primary_color;
  border-color: $primary_color;
}
.btn-submit:hover {
  background-color: $primary_color_hover;
  border-color: $primary_color_hover;
  color: #FFFFFF;
}
.btn-submit:disabled {
  color: #FFFFFF;
  background-color: rgba(22,45,61,.3);
  border-color: transparent;
}
.btn-cancel {
  background-color: transparent !important;
  border-color: $primary_color !important;
  color: $primary_color !important;
}
.btn-cancel:hover {
  background-color: $primary_color_hover !important;
  border-color: $primary_color_hover !important;
  color: #FFFFFF !important;
}
</style>
