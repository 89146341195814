<template>
  <div class="intro-y box p-2 sm:p-5 mt-5">
    <div class="flex items-center justify-between gap-2 mb-5">
      <h4 class="text-lg font-medium leading-none mb-0">
        <app-i18n code="event.fields.sponsors"></app-i18n>
      </h4>

      <button type="button" class="btn bg-theme-31 text-white sm:w-auto flex items-center gap-2" @click="showModal()">
        <PlusIcon class="w-4 h-4" /> {{ i18n('common.add') }}
      </button>
    </div>

    <div class="relative w-full">
      <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />

      <!-- <div class="overflow-auto whitespace-nowrap inline-grid grid-cols-3 gap-5">
        <div v-for="(index) in 20" :key="index" class="inline-block">
          <img src="https://upload.wikimedia.org/wikipedia/en/thumb/e/e2/IMG_Academy_Logo.svg/1200px-IMG_Academy_Logo.svg.png"
           class="rounded-md rounded-md w-32 h-32 object-fill" />
        </div>
      </div> -->

      <!-- <div id="center-mode-slider" class="p-5">
        <div class="preview" style="direction:ltr !important">
          <div class="mx-6">
            <TinySlider v-if="rows.length"
              :options="{
                autoplay: false,
                controls: true,
                items: 1,
                nav: true,
                responsive: {
                  600: {
                    items: 3,
                  },
                  480: {
                    items: 2,
                  },
                },
              }"
            >
              <div class="h-32 px-2" v-for="(row, index) in rows" :key="index">
                <div class="h-full bg-gray-200 dark:bg-dark-1 rounded-md">
                  <div class="relative cursor-pointer">
                    <a :href="row.link" target="_blank">
                      <img class="rounded-md w-full h-32 object-fill" :src="row.logo" alt="Logo" />
                    </a>
                    <Tippy
                      tag="div"
                      content="Remove this logo?"
                      class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-24 right-0 top-0 mr-2 mt-2"
                    >
                      <xIcon class="w-4 h-4" @click="doDelete(index)" />
                    </Tippy>
                  </div>
                </div>
              </div>
            </TinySlider>
          </div>
        </div>
        <div class="flex items-center justify-center w-full" v-if="!rows.length">
          <div class="text-gray-500 font-medium sm:text-lg py-10">{{ isRTL ? 'لا يوجد رعاة' : 'There are no sponsors' }}</div>
        </div>
      </div> -->

      <div class="border-2 border-dashed border-gray-200 rounded-md shadow-sm p-5 overflow-auto">
        <div class="relative cursor-pointer zoom-in inline-block m-3" v-for="(row, index) in rows" :key="index">
          <a :href="row.link" target="_blank">
            <img :src="row.logo" alt="Logo" class="rounded-md rounded-md w-32 h-32 object-fill" />
          </a>
          <Tippy
            tag="div"
            content="Remove this logo?"
            class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-24 right-0 top-0 -mr-2 -mt-2"
          >
            <xIcon class="w-4 h-4" @click="doDelete(index)" />
          </Tippy>
        </div>

        <div class="flex items-center justify-center w-full" v-if="!rows.length">
          <div class="text-gray-500 font-medium sm:text-lg py-10">{{ isRTL ? 'لا يوجد رعاة' : 'There are no sponsors' }}</div>
        </div>
      </div>
    </div>

    <sponsor-create-modal
      ref="modal"
      @submit="doCreate"
      @delete="doDelete"
    ></sponsor-create-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
// import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import SponsorCreateModal from '@/views/event/modal/sponsor-create-modal.vue'

export default defineComponent({
  name: 'event-form-sponsors',
  props: {
    loading: {
      type: Boolean
    },
    rows: {
      type: Array,
      default: () => []
    }
  },
  components: {
    [SponsorCreateModal.name]: SponsorCreateModal
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    showModal() {
      cash('#sponsor-create-modal').modal('show')
    },
    doCreate(record) {
      this.$emit('add', record)
    },
    doDelete(index) {
      // debugger
      this.$emit('remove', index)
    }
  }
})
</script>
